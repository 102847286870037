export const breakpoints = {
  mobile: 450,
  largeMobile: 600,
  tablet: 850,
  largeScreen: 1000,
};

export const media = {
  smallMobile: "@media (max-width: 374px)",
  mobile: `@media (max-width: ${breakpoints.mobile - 1}px)`,
  largeMobile: `@media (max-width: ${breakpoints.largeMobile - 1}px)`, // prettier-ignore
  tablet: `@media (min-width: ${breakpoints.largeMobile}px) and (max-width: ${breakpoints.largeScreen}px)`, // prettier-ignore
  desktop: `@media (min-width: ${breakpoints.largeScreen + 1}px)`,
  largeScreen: `@media (min-width: ${breakpoints.largeScreen + 1}px)`,
  // eslint-disable-next-line quotes
  ipadPro: `@media (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)`,
};

export const portrait = " and (orientation: portrait)";
export const landscape = " and (orientation: landscape)";

export const REFERENCE_FONT_SIZE = 10;
export const rem = (val: number) => `${val / REFERENCE_FONT_SIZE}rem`;
