import React from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import theme from "styles/theme";
import GlobalStyles from "styles/global-styles";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Biom Studio</title>
        <meta
          name="description"
          content="We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist connections, and unique experiences through gaming and live events. Explore with us!"
        />
        {/* Open Graph metadata */}
        <meta property="og:title" content="Biom Studio" />
        <meta
          property="og:description"
          content="We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist connections, and unique experiences through gaming and live events. Explore with us!"
        />
        <meta
          property="og:image"
          content="https://www.biom.studio/images/openGraph.png"
        />
        <meta property="og:url" content="https://www.biom.studio/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Biom Studio" />
        <meta
          property="og:description"
          content="We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist connections, and unique experiences through gaming and live events. Explore with us!"
        />
        {/* X (Twitter) Card metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Biom Studio" />
        <meta
          name="twitter:description"
          content="We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist connections, and unique experiences through gaming and live events. Explore with us!"
        />
        <meta
          name="twitter:image"
          content="https://www.biom.studio/images/openGraph.png"
        />
      </Head>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}

export default MyApp;
