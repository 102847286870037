import { DefaultTheme } from "styled-components";
import { landscape, media, portrait } from "./styleConstants";

const theme: DefaultTheme = {
  fonts: {
    vt323: "'VT323', monospace",
    daysOne: "'Days One', sans-serif",
  },
  palette: {
    green: "#00ff00ff",
    red: "#ff0049ff",
    blue: "#3636ffff",
  },
  responsive: {
    media,
    portrait,
    landscape,
  },
};

export default theme;
